<template>
    <div class="login-page">
        <div class="login-box">
            <b-overlay :show="loading" rounded="sm" spinner-variant="info">
                <div class="card card-outline card-info">
                    <div class="card-header text-center">
                        <h3 class="m-0"><b>{{ appName }}</b></h3>
                    </div>
                    <div class="card-body">
                        <p class="login-box-msg">Đăng nhập để tiếp tục</p>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" id="username" placeholder="Tài khoản"
                                v-model="object.username" v-on:keyup="keyEnter">
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-user"></span>
                                </div>
                            </div>
                        </div>
                        <div class="input-group mb-3">
                            <input type="password" class="form-control" id="password" placeholder="Mật khẩu"
                                v-model="object.password" v-on:keyup="keyEnter">
                            <div class="input-group-append">
                                <div class="input-group-text">
                                    <span class="fas fa-lock"></span>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="icheck-info">
                                    <input type="checkbox" id="remember" v-model="remember">
                                    <label for="remember">
                                        Ghi nhớ
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <button class="btn btn-info btn-block" v-on:click="signIn()">Đăng nhập</button>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="card-footer">
                        <p class="mb-1">
                            <a class="link-click" v-on:click="goToForgot()">Quên mật khẩu</a>
                        </p>
                    </div> -->
                </div>
            </b-overlay>
        </div>
    </div>
</template>

<script>
import { saveAdminAuthen, getAdminRemember, saveAdminRemember, removeAdminRemember } from "../common/storage";
import ApiGeneral from "../services/apiGeneral";


export default {
    data: function () {
        return {
            object: {
                username: '',
                password: '',
            },
            remember: false,
            loading: false
        };
    },
    methods: {
        checkValid() {
            if (this.object) return this.checkValidForm([
                { id: "username", value: this.object.username, message: 'Vui lòng nhập Tài khoản' },
                { id: "password", value: this.object.password, message: 'Vui lòng nhập Mật khẩu' }
            ]);
            return false;
        },
        async signIn() {
            if (this.checkValid()) {
                this.loading = true;
                const data = await ApiGeneral.signIn(this.object)
                this.loading = false;
                console.log("data>>>", data)
                this.checkResponse(data, () => {
                    console.log("checkResponse data>>>", data)
                    saveAdminAuthen(data);
                    removeAdminRemember();
                    if (this.remember) saveAdminRemember(this.object);
                    location.reload();
                }, () => {
                    this.notifyToast('Tài khoản hoặc Mật khẩu chưa đúng.', 'danger');
                });
            }
        },
        keyEnter(e) {
            if (e.which == 13) this.signIn()
        }
    },
    mounted() {
        let remember = getAdminRemember();
        if (remember) {
            this.object = remember;
            this.remember = true;
        }
        this.setTitle("Đăng nhập");
    }
};
</script>